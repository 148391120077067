nav.navbar {
  background: transparent;
  position: sticky;
  top: 0;
}

.paperedge-top:before{
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 30px;
  top: 0;
  background: var(--paperwhite);
}

.paperedge-top:after{
  content: '';
  display: block;
  width: 100%;
  height: 65px;
  position: absolute;
  margin-top: 25px;
  background-image: var(--background-image);
  z-index:0;
  filter: drop-shadow( 5px 10px 5px rgba(0, 0, 0, .05));
}

nav.navbar a {
  position: relative;
  color: black;
}

nav.navbar .navbar-menu.is-active {
  background: var(--paperwhite);
  border-radius: 1rem;
}

nav.navbar a:hover {
  background: transparent;
}
